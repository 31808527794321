<template>
  <div>

    <div v-if="$route.params.address" class="lazymint-container">
      <div id="collection-lazymint-background" />
    </div>

    <div ref="thisComponent" class="lazy-mint-layout-desktop">
      <div id="launcher-header">

        <div :class="collectionHeaderContents">

          <div id="collection-detail" :class="collectionDetail">
            <div class="flex-col">
              <div class="flex-row-between">
                <div
                  v-if="collection.name"
                  class="collection-header-name"
                >
                  {{ collection.name }}
                </div>
                <div
                  v-if="collection.symbol"
                  class="symbol-text-out"
                >
                  <b>/ {{ collection.symbol }}</b>
                </div>
                <div
                  v-if="collection.creator_name"
                  class="symbol-text-out"
                >
                  <b> @ {{ collection.creator_name }}</b>
                </div>
              </div>
              <div
                class="main-value-container flex-row"
              >
                <div>
                  <div class="funds-header neon-text">
                      ${{ mintedValue }}
                  </div>
                  <div>
                      <div class="sub-header">Community Token Goal.</div>
                  </div>
                </div>
                <div class="spacer">
                </div>
                <div>
                  <div class="funds-header flex-row-end neon-text">
                     {{ amountMinted }}
                  </div>
                  <div class="flex-row-end">
                      <div class="sub-header">{{ localize('TEXT_TOTAL_TOKENS_MINTED') }}</div>
                  </div>
                </div>
              </div>
              <div class="goal-bar">
                <div id="goal-bar-progress" />
              </div>
              <div
                class="symbol-text flex-row"
              >
                {{ localize('TEXT_GOAL') }} : {{ goal }} %
              </div>
            </div>
            <div class="flex-col">
              <div class="flex-row-between">
                <div
                  class="symbol-text flex-row neon-text"
                >
                  {{ localize('TEXT_TOTAL_DILUTED_VALUE') }} : ${{ dilutedValue }}
                </div>
                <div
                  class="symbol-text flex-row neon-text"
                >
                  {{ localize('TEXT_TOTAL_SUPPLY') }} : {{ totalSupply }}
                </div>
              </div>
              <div class="flex-row-between">
                <div
                  class="symbol-text flex-row neon-text"
                >
                  {{ localize('TEXT_INTRADAY_VOLUME') }} : {{ dayVolume }}
                </div>
                <div
                  class="symbol-text flex-row neon-text"
                >
                  {{ localize('TEXT_FLOOR_PRICE') }} : ${{ floorPrice }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>
<script>

import { toNumber } from 'web3-utils';
import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch, watchEffect} from "vue";
import {translationStore} from "@/stores/translation";
import {equityDAONFTABI} from "../abi.js";
import Axios from "axios";

export default {
    "name": "Launcher",
    "components": {
    },
    "props": {
        "nft_address": {
            "type": String,
            "default": ""
        }
    },
    setup (props) {

        const translation = translationStore(),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            route = inject("route"),
            router = inject("router"),
            icon = iconStore(),
            web3 = inject("web3"),
            priceFeed = ref(),
            thisComponent = ref(),
            collectionHeaderContents = ref(),
            collectionDetail = ref(),
            nft_address = ref(props.nft_address),
            collection = reactive({
                "id": 0,
                "name": "",
                "symbol": "",
                "description": "",
                "owner": "",
                "image": "",
                "banner": "",
                "video": "",
                "file": {},
                "primary_color": "#000",
                "secondary_color": "#dde6e8"
            }),
            goal = ref(),
            mintedValue = ref(),
            dilutedValue = ref(),
            totalSupply = ref(),
            amountMinted = ref(),
            dayVolume = ref(),
            floorPrice = ref(),
            getCollectionStats = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getCollectionStats`,
                    {

                        "params": {

                            "nft_address": nft_address.value

                        }

                    }
                ).
                    then((response) => {

                        if (!response.data.min) {

                          response.data.min = 0;

                        }

                        if (!response.data.day_volume) {

                          response.data.day_volume = 0;

                        }

                        dayVolume.value = (web3.value.utils.fromWei(
                            response.data.day_volume,
                            "ether"
                        ) * priceFeed.value.ethPriceUSD).toFixed(2);

                        floorPrice.value = (web3.value.utils.fromWei(
                            response.data.min,
                            "ether"
                        ) * priceFeed.value.ethPriceUSD).toFixed(2);

                    });

            },
            getCollection = function (address) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getCollection`,

                    {

                        "params": {

                            address

                        }

                    }

                ).
                    then((response) => {

                        collection.value = {};

                        // Object.assign(collection, response.data);
                        for (const key in response.data) {

                            collection[key] = response.data[key];

                        }

                        // grab the onchain data, this can be a fallback or for non platform collections
                        const grabOnChain = async () => {

                            const amc = new web3.value.eth.Contract(
                                equityDAONFTABI,
                                collection.nft_address
                            );
                            amc.methods.contractURI().call().
                                then((jsonRpcResult) => {

                                    if (jsonRpcResult) {

                                        const b64meta = JSON.parse(decodeURIComponent(escape(atob(jsonRpcResult.replace(
                                            /^data:\w+\/\w+;base64,/,
                                            ""
                                        )))));

                                        for (const key in b64meta) {

                                            if (b64meta[key]) {

                                                collection[key] = b64meta[key];

                                            }

                                        }

                                    }

                                });

                        };

                        if (collection.nft_address) {

                            if (collection.chain_id === serverConfig.chainId) {

                                grabOnChain();

                            }

                            updateView();

                        }

                    });

            },
            moneyCounter = function (value) {

                setTimeout(

                  function() {

                    

                  }, 100

                );

            },
            getIcon = function (i) {

                return icon.get(`${i}`);

            },
            updateView = function () {

                const cw = thisComponent.value.clientWidth;

                if (cw > 800) {

                    collectionHeaderContents.value = "collection-header-contents-desktop";
                    collectionDetail.value = "collection-detail-desktop";

                } else {

                    collectionHeaderContents.value = "collection-header-contents-mobile";
                    collectionDetail.value = "collection-detail-mobile";

                }

                setTimeout(

                    () => {

                        const c = document.getElementById("launcher-header");
                        if (c) {

                            if (collection.primary_color && collection.secondary_color) {

                                c.style.background = "linear-gradient(15deg, #060636, #651378)";
                                c.style.color = "#fff";

                            }

                            if (serverConfig.view == "mobile") {

                                c.style.width = "100%";

                            } else {

                                c.style.width = "100%";

                            }

                        }

                    },
                    100

                );

            };

        onMounted(() => {

            priceFeed.value = serverConfig.priceFeed;

            if (!nft_address.value) {

                nft_address.value = route.params.address

            }

            if (nft_address.value) {

                getCollection(nft_address.value);

            }
            // getCollectionStats();

            setTimeout(
                () => {

                    updateView();

                },
                1000
            );

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            serverConfig,
            nft_address,
            thisComponent,
            priceFeed,
            goal,
            mintedValue,
            dilutedValue,
            totalSupply,
            amountMinted,
            dayVolume,
            floorPrice,
            collectionHeaderContents,
            collectionDetail,
            getCollectionStats,
            getIcon,
            collection,
            updateView,
            getCollection,
            userInfo,
            "icon": icon.get};

    }
};

</script>
<style scoped>
svg.dashboard-svg-desktop {
  position: absolute;
  background: linear-gradient(45deg, #00947aa3, rgb(27 78 101));
  padding-top: 150px;
  left: 0;
}
svg.dashboard-svg-mobile {
  position: absolute;
  background: linear-gradient(45deg, #00947aa3, rgb(27 78 101));
  padding-top: 200px;
  left: 0;
}
#collection-detail {
  padding: 50px;
}
#launcher-header {
  border-radius: 50px;
}
.container {
  margin-top: -100px;
  text-align: left;
}
.asset-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
}
.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.text-button {
  margin-top: 10px;
  font-size: 0.9em;
  color: black;
}
.funds-header {
  font-size: 5vw;
}
.collection-header-name {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.2em;
}
.sub-header {
  font-size: 0.75rem !important;
  line-height: 1.25 !important;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.symbol-text {
  margin-top: 10px;
  font-size: 0.75em;
  font-weight: bold;
}
.symbol-text-out {
  font-size: 0.75em;
  font-weight: bold;
}
.goal-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px solid #ff98c5;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 1px #f84e9b;
  background: linear-gradient(90deg, white, #ffffff00);
}
#goal-bar-progress {
  height: 20px;
  width: 0%;
  min-width: 20px;
  margin-left: 5px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 5px #fff;
  background: linear-gradient(45deg,#028fff,#ff6acf);
  transition: all;
}
.main-value-container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.neon-text {
  text-shadow:
    0 0 4px #fff,
    0 0 8px #fff,
    0 0 16px #fff,
    0 0 32px #fff,
    0 0 48px #fff;
}
/*
    0 0 1px #fff,
    0 0 2px #fff,
    0 0 40px #fff,
    0 0 50px #fff;
*/
.spacer {
  margin: 20px;
}
</style>
