<template>
  <div>
    <div class="admin-bg-head">

      <div class="admin-contents">

        <div>
          <h1 style="color: #22255c; padding-top: 20px;">
            Admin Panel
          </h1>
        </div>

        <div class="tab-selector">

          <div class="tabs">
            <div
              v-for="(tab, index) in tabs"
              :key="tab"
              class="tab"
              :class="{ active: selectedTab === tab }"
              @click="selectTab(tab)"
            >
              {{ tab }}
            </div>
            <div
              class="indicator"
              :style="{ transform: `translateX(${activeIndex * 100}%)` }"
            ></div>
          </div>

          <div class="tab-content-wrapper">
            <Transition
              name="gnarly-slide"
            >
              <div
                v-show="selectedTab === 'update'"
                class="tab-content"
                :style="{ transitionDelay: k * 300 + 'ms' }"
              >

                <h2 style="color: #22255c; margin: 40px;">
                  Update Tokens
                </h2>

                <div
                  class="flex-row-between"
                  v-for="(i,k) in products"
                >
                  <div>
                    <CInput
                      class="address-box"
                      :value="i.address"
                      :title="k + ' Address'"
                      placeholder="0x.."
                      @inputValue="i.address = $event"
                    />
                    <div class="flex-row">
                      <div
                        class="subscript"
                        @click="setAddress(k,i.address)"
                      >
                        Set
                      </div>
                      <div
                        class="subscript"
                        @click="gotoUrl('https://arbiscan.io/token/' + i.address)"
                      >
                        &nbsp;&nbsp;<i>view</i>
                      </div>
                    </div>
                  </div>
                  <div>
                    <CInput
                      class="num-box"
                      :value="i.price"
                      :title="k + ' Mint Price'"
                      placeholder="price in USD"
                      @inputValue="i.price = $event"
                    />
                    <div
                      class="subscript"
                      @click="setPrice(k,i.address,i.price)"
                    >
                      Set
                    </div>
                  </div>
                  <div>
                    <CInput
                      class="num-box"
                      :value="i.fee"
                      :title="k + ' Demurrage Fee'"
                      placeholder="demurrage fee"
                      @inputValue="i.fee = $event"
                    />
                    <div
                      class="subscript"
                      @click="setFee(k, i.fee)"
                    >
                      Set
                    </div>
                  </div>
                  <div>
                    <CInput
                      class="num-box"
                      :value="i.limit"
                      :title="k + ' Daily Limit'"
                      placeholder="daily limit"
                      @inputValue="i.limit = $event"
                    />
                    <div
                      class="subscript"
                      @click="setLimit(k, i.limit)"
                    >
                      Set
                    </div>
                  </div>
                </div>
                <div class="buttons-container">
                  <div
                    @click="showAddRWAMinterModal = true"
                    class="basic-button"
                  >
                    Add Minter
                  </div>
                  <div
                    @click="showRemRWAMinterModal = true"
                    class="basic-button"
                  >
                    Remove Minter
                  </div>
                </div>
<!--
                <div class="buttons-container">
                  <div
                    @click="showAddMinterModal = true"
                    class="basic-button"
                  >
                    Add Minter
                  </div>
                  <div
                    @click="showRemMinterModal = true"
                    class="basic-button"
                  >
                    Remove Minter
                  </div>
                </div>
-->
              </div>
            </Transition>
            <Transition name="gnarly-slide">
              <div
                v-show="selectedTab === 'create'"
                class="tab-content"
                :style="{ transitionDelay: k * 300 + 'ms' }"
              >

                <div>
                  <CreateERC20 
                    :mintAddress
                    :tokenFactoryAddress
                    :rwaTokenFactoryAddress
                  />
                </div>

              </div>
            </Transition>
            <Transition
              name="gnarly-slide"
            >
              <div
                v-show="selectedTab === 'system'"
                class="tab-content"
                :style="{ transitionDelay: k * 300 + 'ms' }"
              >

                <h1 style="color: #22255c; margin: 40px;">
                  System
                </h1>

                <div
                  class="flex-column-around"
                >
                  <div>
                    <div class="flex-row">
                      <CInput
                        class="address-box"
                        :value="mintAddress"
                        title="Raredex Mint Address"
                        placeholder="0x.."
                        @inputValue="mintAddress = $event"
                      />
                      <div class="buttons-container">
                        <div
                          @click="showAddSwappableModal = true"
                          class="basic-button"
                        >
                          Add Swap Token
                        </div>
                        <div
                          @click="showRemSwappableModal = true"
                          class="basic-button"
                        >
                          Remove Swap Token
                        </div>
                        <div
                          @click="showAddMintableModal = true"
                          class="basic-button"
                        >
                          Add Mint Token
                        </div>
                        <div
                          @click="showRemMintableModal = true"
                          class="basic-button"
                        >
                          Remove Mint Token
                        </div>
                      </div>
                    </div>
                    <div
                      class="subscript"
                      @click="setMintAddress"
                    >
                      Set
                    </div>
                  </div>
                  <div>
                    <div class="flex-row">
                      <CInput
                        class="address-box"
                        :value="tokenFactoryAddress"
                        title="Token (ERC20) Factory Address'"
                        placeholder="0x.."
                        @inputValue="tokenFactoryAddress = $event"
                      />
                      <div class="buttons-container">
                        <div
                          @click="showAddFactoryAdminModal = true"
                          class="basic-button"
                        >
                          Add Admin
                        </div>
                        <div
                          @click="showRemFactoryAdminModal = true"
                          class="basic-button"
                        >
                          Remove Admin
                        </div>
                      </div>
                    </div>
                    <div
                      class="subscript"
                      @click="setTokenFactoryAddress(k)"
                    >
                      Set
                    </div>
                  </div>
                  <div>
                    <div class="flex-row">
                      <CInput
                        class="address-box"
                        :value="rwaTokenFactoryAddress"
                        title="Token (RWA) Factory Address'"
                        placeholder="0x.."
                        @inputValue="rwaTokenFactoryAddress = $event"
                      />
                      <div class="buttons-container">
                        <div
                          @click="showAddRWAFactoryAdminModal = true"
                          class="basic-button"
                        >
                          Add Admin
                        </div>
                        <div
                          @click="showRemRWAFactoryAdminModal = true"
                          class="basic-button"
                        >
                          Remove Admin
                        </div>
                      </div>
                    </div>
                    <div
                      class="subscript"
                      @click="setTokenRWAFactoryAddress(k)"
                    >
                      Set
                    </div>
                  </div>

                  <div class="flex-row">
                    <CInput
                      class="address-box"
                      :value="withdrawTokenAddress"
                      title="Token Address"
                      placeholder="eg. USDC address 0x.."
                      @inputValue="withdrawTokenAddress = $event"
                    />
                    <CInput
                      class="num-box"
                      :value="withdrawAmount"
                      title="Withdraw Amount"
                      placeholder="0"
                      @inputValue="withdrawAmount = $event"
                    />
                    <div
                      class="admin-withdraw-button"
                      @click="withdraw"
                    >
                      Withdraw
                    </div>
                  </div>
                </div>

              </div>
            </Transition>

          </div>

        </div>

      </div>

    </div>

    <div class="admin-bg-main" />

    <CModal
      v-if="showAddSwappableModal"
      width="medium"
      color="#4a90e2"
      title="Add Swappable"
      @close="showAddSwappableModal = false"
    >
      <template #header>
        <div> Add Swappable </div>
      </template>
      <template #body>
        <div>
          eg USDC (in BP) : 100
          <CInput
            title="Token Price (DO NOT SET 0)"
            placeholder="NOT ZERO"
            @inputValue="swappableTokenPrice = $event"
          />
          <CInput
            title="Token Address"
            placeholder="0"
            @inputValue="swappableToken = $event"
          />
          <div
            class="admin-save-button"
            @click="addSwappable"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemSwappableModal"
      width="medium"
      color="#4a90e2"
      title="Address Set"
      @close="showRemSwappableModal = false"
    >
      <template #header>
        <div> Remove Swappable </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0"
            @inputValue="swappableToken = $event"
          />
          <div
            class="admin-save-button"
            @click="remSwappable"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAddMintableModal"
      width="medium"
      color="#4a90e2"
      title="Add Mintable Token"
      @close="showAddMintableModal = false"
    >
      <template #header>
        <div> Add Mintable </div>
      </template>
      <template #body>
        <div>
          Metal Price in USD, eg. Gallium : 755
          <CInput
            title="Token Price (DO NOT SET 0)"
            placeholder="NOT ZERO"
            @inputValue="mintableTokenPrice = $event"
          />
          <CInput
            title="Token Address"
            placeholder="0"
            @inputValue="mintableToken = $event"
          />
          <div
            class="admin-save-button"
            @click="addMintable"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemMintableModal"
      width="medium"
      color="#4a90e2"
      title="Remove Mintable Token"
      @close="showRemMintableModal = false"
    >
      <template #header>
        <div> Remove Mintable </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0"
            @inputValue="mintableToken = $event"
          />
          <div
            class="admin-save-button"
            @click="remMintable"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAddMinterModal"
      width="medium"
      color="#4a90e2"
      title="Add Minter"
      @close="showAddMinterModal = false"
    >
      <template #header>
        <div> Add Minter </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0x"
            @inputValue="minterSelectedTokenAddress = $event"
          />
          <CInput
            title="Wallet Address"
            placeholder="0"
            @inputValue="minterAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="addMinter"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemMinterModal"
      width="medium"
      color="#4a90e2"
      title="Remove Minter"
      @close="showRemMinterModal = false"
    >
      <template #header>
        <div> Remove Minter </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0x"
            @inputValue="minterSelectedTokenAddress = $event"
          />
          <CInput
            title="Wallet Address"
            placeholder="0"
            @inputValue="minterAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="remMinter"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAddFactoryAdminModal"
      width="medium"
      color="#4a90e2"
      title="Add Admin"
      @close="showAddFactoryAdminModal = false"
    >
      <template #header>
        <div> Add Factory Admin </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Wallet Address"
            placeholder="0x"
            @inputValue="addAdminAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="addFactoryAdmin"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemFactoryAdminModal"
      width="medium"
      color="#4a90e2"
      title="Add Admin"
      @close="showRemFactoryAdminModal = false"
    >
      <template #header>
        <div> Remove Factory Admin </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Wallet Address"
            placeholder="0x"
            @inputValue="addAdminAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="remFactoryAdmin"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAddRWAFactoryAdminModal"
      width="medium"
      color="#4a90e2"
      title="Add Admin"
      @close="showAddRWAFactoryAdminModal = false"
    >
      <template #header>
        <div> Add RWA Factory Admin </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Wallet Address"
            placeholder="0x"
            @inputValue="addAdminAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="addRwaFactoryAdmin"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemRWAFactoryAdminModal"
      width="medium"
      color="#4a90e2"
      title="Add Admin"
      @close="showRemRWAFactoryAdminModal = false"
    >
      <template #header>
        <div> Remove RWA Factory Admin </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Wallet Address"
            placeholder="0x"
            @inputValue="addAdminAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="remRwaFactoryAdmin"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAddRWAMinterModal"
      width="medium"
      color="#4a90e2"
      title="Add Minter"
      @close="showAddRWAMinterModal = false"
    >
      <template #header>
        <div> Add RWA Minter </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0x"
            @inputValue="minterSelectedTokenAddress = $event"
          />
          <CInput
            title="Wallet Address"
            placeholder="0x"
            @inputValue="minterAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="addRWAMinter"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showRemRWAMinterModal"
      width="medium"
      color="#4a90e2"
      title="Remove Minter"
      @close="showRemRWAMinterModal = false"
    >
      <template #header>
        <div> Remove Minter </div>
      </template>
      <template #body>
        <div>
          <CInput
            title="Token Address"
            placeholder="0x"
            @inputValue="minterSelectedTokenAddress = $event"
          />
          <CInput
            title="Wallet Address"
            placeholder="0"
            @inputValue="minterAddress = $event"
          />
          <div
            class="admin-save-button"
            @click="remRWAMinter"
          >
            Set
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showAdminModal"
      width="medium"
      color="#4a90e2"
      :title="localize('ADD_CREATOR')"
      @close="showAdminModal = false"
    >
      <template #header>
        <div> Edit Admin </div>
      </template>
      <template #body>
        <div class="flex-col">
          <CInput
            :value="pAdmin"
            :title="localize('TEXT_ADDRESS')"
            :placeholder="localize('PROFILE_NAME_PLACEHOLDER')"
            type="string"
            class="admin-item"
            @inputValue="pAdmin = $event"
          />
          <div
            class="cinputTitle"
          >
            {{ localize('TEXT_ABOUT') }}
          </div>
          <div>
            <div
              class="admin-save-button"
              @click="addAdminOnChain"
            >
              {{ localize('TEXT_ADD_ADMIN') }}
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, computed, watch} from "vue";
import {translationStore} from "@/stores/translation";
import CreateERC20 from "./CreateERC20.vue";
import Axios from "axios";
import {erc20ABI,
  erc20FactoryABI,
  rwaTokenABI,
  rwaTokenFactoryABI,
  iRwaMintableABI,
  iRwaTokenABI,
  raredexMintABI} from "../abi.js";

export default {
    "name": "Admin",
    "components": {
      CreateERC20
    },
    "props": {
    },
    setup (props, {emit}) {

        const eBus = inject("eBus"),
            translation = translationStore(),
            emitAppEvent = (e, d) => {

                eBus.emit(
                    "app-event",
                    {
                        "action": e,
                        "data": d
                    }
                );

            },
            icon = iconStore(),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            priceContract = ref("0x820Ac7703768638C5F539aC7C5Ac08d7811c754C"),
            showAdminModal = ref(false),
            showSetAddressModal = ref(false),
            showAddSwappableModal = ref(false),
            showRemSwappableModal = ref(false),
            showAddMintableModal = ref(false),
            showRemMintableModal = ref(false),
            showAddMinterModal = ref(false),
            showRemMinterModal = ref(false),
            showAddRWAMinterModal = ref(false),
            showRemRWAMinterModal = ref(false),
            showAddFactoryAdminModal = ref(false),
            showRemFactoryAdminModal = ref(false),
            showAddRWAFactoryAdminModal = ref(false),
            showRemRWAFactoryAdminModal = ref(false),
            minterSelectedTokenAddress = ref(),
            swappableToken = ref(),
            mintableToken = ref(),
            swappableTokenPrice = ref(),
            mintableTokenPrice = ref(),
            minterAddress = ref(),
            addAdminAddress = ref(),
            pAccount = ref(""),
            pAdmin = ref(""),
            pUserId = ref(""),
            web3 = inject("web3"),
            tabs = ref(['update', 'create', 'system']),
            selectedTab = ref('update'),
            selectTab = (tab) => {

              selectedTab.value = tab;

            },
            activeIndex = computed(() => tabs.value.indexOf(selectedTab.value)),
            products = ref({}),
            mintAddress = ref(),
            tokenFactoryAddress = ref(),
            rwaTokenFactoryAddress = ref(),
            withdrawAmount = ref(),
            withdrawTokenAddress = ref(),
            getAllERC20Tokens = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getAllERC20Tokens`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        for (let i = 0; i < response.data.length; i++) {

                            const asset = response.data[i].name;

                            if (asset) {

                                products.value[asset] = response.data[i];

                            }

                        }

                  });

            },
            getAddresses = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getSystemAddress`,
                    {
                        "params": {
                        }
                    }

                ).
                    then((response) => {

                    const a = response.data[0];

                    mintAddress.value = a.mint;
                    tokenFactoryAddress.value = a.token_factory;
                    rwaTokenFactoryAddress.value = a.rwa_token_factory;

                })

            },
            setMintAddress = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setSystemAddress`,
                    {
                        "params": {
                            type:"mint",
                            address:mintAddress.value
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showSetAddressModal.value = true;

                        }

                    })
                
            },
            setTokenFactoryAddress = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setSystemAddress`,
                    {
                        "params": {
                            type:"tokenFactory",
                            address:tokenFactoryAddress.value
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showSetAddressModal.value = true;

                        }

                    })
                
            },
            setTokenRWAFactoryAddress = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setSystemAddress`,
                    {
                        "params": {
                            type:"rwaTokenFactory",
                            address:rwaTokenFactoryAddress.value
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showSetAddressModal.value = true;

                        }

                    })
                
            },
            setAddress = async function (name, token) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setTokenAddress`,
                    {
                        "params": {
                            name,
                            token
                        }
                    }

                ).
                    then((response) => {

                    const a = response.data[0];
                    console.log(a);

                })

            },
            setPrice = async function (name,token,price) {

                const price_ = price * 10**6;

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(token)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );

                    let gasLimit = await amc.methods.setMintPrice(
                            token,
                            price_,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.setMintPrice(
                        token,
                        price_,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");

                            Axios.get(

                                `${process.env.VUE_APP_SERVER_URI}setTokenPrice`,
                                {
                                    "params": {
                                        name,
                                        token,
                                        price_
                                    }
                                }

                            ).
                            then((response) => {

                            const a = response.data[0];
                            console.log(a);

                        })

                    });

                }

            },
            setFee = async function (asset, fee) {

                let token = products.value[asset].address;

                if (web3.value.utils.isAddress(token) === true) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            rwaTokenABI,
                            token
                        );

                    let gasLimit = await amc.methods.setFee(
                            fee,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.setFee(
                        fee
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            showAddSwappableModal.value = false;
                            console.log("jsonRpcResult");

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            setLimit = async function (asset, limit) {

                let token = products.value[asset].address;

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(token)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],

                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );

                    let gasLimit = await amc.methods.setDailyMintLimit(
                            token,
                            limit,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.setDailyMintLimit(
                        token,
                        limit,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            showAddSwappableModal.value = false;
                            console.log("jsonRpcResult");

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addSwappable = async function () {

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(swappableToken.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],

                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );
                    let gasLimit = await amc.methods.addSwappable(
                            swappableTokenPrice.value,
                            swappableToken.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addSwappable(
                        swappableTokenPrice.value,
                        swappableToken.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            showAddSwappableModal.value = false;
                            console.log("jsonRpcResult");

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remSwappable = async function () {

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(swappableToken.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],

                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );
                    let gasLimit = await amc.methods.removeSwappable(
                            swappableToken.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeSwappable(
                        swappableToken.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            showRemSwappableModal.value = false;
                            console.log("jsonRpcResult");

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addMintable = async function () {

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(mintableToken.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );

                    let gasLimit = await amc.methods.addMintable(
                            mintableTokenPrice.value * (10**6),
                            mintableToken.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addMintable(
                        mintableTokenPrice.value * (10**6),
                        mintableToken.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showAddMintableModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remMintable = async function () {

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(mintableToken.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );

                    let gasLimit = await amc.methods.removeToken(
                            mintableToken.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeToken(
                        mintableToken.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showRemMintableModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addFactoryAdmin = async function () {

                if (web3.value.utils.isAddress(addAdminAddress.value) === true &&
                  web3.value.utils.isAddress(tokenFactoryAddress.value) === true) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            erc20FactoryABI,
                            tokenFactoryAddress.value
                        );

                    let gasLimit = await amc.methods.addAdmin(
                            addAdminAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addAdmin(
                        addAdminAddress.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showAddFactoryAdminModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remFactoryAdmin = async function () {

                if (web3.value.utils.isAddress(addAdminAddress.value) === true &&
                  web3.value.utils.isAddress(tokenFactoryAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            erc20FactoryABI,
                            tokenFactoryAddress.value
                        );

                    let gasLimit = await amc.methods.removeAdmin(
                            addAdminAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeAdmin(
                        addAdminAddress.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showRemFactoryAdminModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addRwaFactoryAdmin = async function () {

                if (web3.value.utils.isAddress(addAdminAddress.value) === true &&
                  web3.value.utils.isAddress(tokenFactoryAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            rwaTokenFactoryABI,
                            rwaTokenFactoryAddress.value
                        );

                    let gasLimit = await amc.methods.addAdmin(
                            addAdminAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addAdmin(
                        addAdminAddress.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showAddRWAFactoryAdminModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remRwaFactoryAdmin = async function () {

                if (web3.value.utils.isAddress(addAdminAddress.value) === true &&
                  web3.value.utils.isAddress(tokenFactoryAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            rwaTokenFactoryABI,
                            rwaTokenFactoryAddress.value
                        );

                    let gasLimit = await amc.methods.removeAdmin(
                            addAdminAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeAdmin(
                        addAdminAddress.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showRemRWAFactoryAdminModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addMinter = async function () {

                if (web3.value.utils.isAddress(minterAddress.value) === true &&
                  web3.value.utils.isAddress(minterSelectedTokenAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            erc20ABI,
                            minterSelectedTokenAddress.value
                        );

                    let gasLimit = await amc.methods.addMinter(
                            minterAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addMinter(
                        minterAddress.value
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showAddMinterModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remMinter = async function () {

                if (web3.value.utils.isAddress(minterAddress.value) === true &&
                  web3.value.utils.isAddress(minterSelectedTokenAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            erc20ABI,
                            minterSelectedTokenAddress.value
                        );

                    let gasLimit = await amc.methods.removeMinter(
                            minterAddress.value
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeMinter(
                        minterAddress.value
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showRemMinterModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addRWAMinter = async function () {

                if (web3.value.utils.isAddress(minterSelectedTokenAddress.value) === true &&
                  web3.value.utils.isAddress(minterAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            rwaTokenABI,
                            minterSelectedTokenAddress.value
                        );

const owner = await amc.methods.owner().call();
console.log("Owner address:", owner);
console.log(amc)
console.log("minterSelectedTokenAddress " + minterSelectedTokenAddress.value)
console.log("minterAddress.value " + minterAddress.value)

                    let gasLimit = await amc.methods.addMinter(
                            minterAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});
console.log('xyz')

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.addMinter(
                        minterAddress.value
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showAddMinterModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            remRWAMinter = async function () {

                if (web3.value.utils.isAddress(minterSelectedTokenAddress.value) === true &&
                  web3.value.utils.isAddress(minterAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            iRwaTokenABI,
                            minterSelectedTokenAddress.value
                        );

                    let gasLimit = await amc.methods.addMinter(
                            minterAddress.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.removeMinter(
                        minterAddress.value
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            showRemMinterModal.value = false;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            withdraw = async function () {

                if (web3.value.utils.isAddress(mintAddress.value) === true &&
                  web3.value.utils.isAddress(withdrawTokenAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            mintAddress.value
                        );

                    let gasLimit = await amc.methods.withdraw(
                            withdrawTokenAddress.value,
                            withdrawAmount.value,
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.withdraw(
                        withdrawTokenAddress.value,
                        withdrawAmount.value,
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");

                        });

                }

            },
            getIcon = function (i) {

                if (serverConfig.visualMode === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            addAdminOnChain = async function () {

                if (pAdmin.value) {

                    if (web3.value.utils.isAddress(pAdmin.value) === true) {

                        const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                            addr = accounts[0],

                            amc = new web3.value.eth.Contract(
                                rareiumPlatformABI,
                                `${serverConfig.VUE_APP_PLATFORM_ADDRESS}`
                            );

                        gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                        let gasPrice = await web3.value.eth.getGasPrice();

                        gasPrice = BigInt(gasPrice) * 12n / 10n;
                        gasPrice = web3.value.utils.toHex(Number(gasPrice));

                        amc.methods.addRareiumAdmin(pAdmin.value).send({
                            "from": addr,
                            gasPrice,
                            gasLimit
                        }).
                            then((jsonRpcResult) => {

                                console.log("jsonRpcResult");
                                showAdminModal.value = false;
                                // addAdmin();

                            });

                    } else {

                        alert("please provide a vaid ethereum address");

                    }

                }

            },
            gotoUrl = function (url) {

                window.open(url, "_blank");

            };

        onMounted(() => {

            getAddresses();
            getAllERC20Tokens();

            emitAppEvent(
                "setNetwork",
                42161
            );

            watch(
                () => pAccount.value,
                () => {

                    findCreator(pAccount.value);

                }
            );
            watch(
                () => userInfo.accounts,
                () => {

                }
            );

        });

        return {"localize": translation.localize,
            "icon": icon.get,
            emitAppEvent,
            getIcon,
            getAllERC20Tokens,
            getAddresses,
            addAdminAddress,
            showAdminModal,
            showSetAddressModal,
            showAddSwappableModal,
            showRemSwappableModal,
            showAddMintableModal,
            showRemMintableModal,
            showAddMinterModal,
            showRemMinterModal,
            showAddRWAMinterModal,
            showRemRWAMinterModal,
            showAddFactoryAdminModal,
            showRemFactoryAdminModal,
            showAddRWAFactoryAdminModal,
            showRemRWAFactoryAdminModal,
            minterSelectedTokenAddress,
            swappableToken,
            mintableToken,
            swappableTokenPrice,
            mintableTokenPrice,
            minterAddress,
            gotoUrl,
            pAccount,
            pAdmin,
            pUserId,
            tabs,
            selectedTab,
            selectTab,
            activeIndex,
            products,
            setFee,
            setLimit,
            setAddress,
            setPrice,
            mintAddress,
            tokenFactoryAddress,
            rwaTokenFactoryAddress,
            setMintAddress,
            setTokenFactoryAddress,
            setTokenRWAFactoryAddress,
            addSwappable,
            remSwappable,
            addMintable,
            remMintable,
            addFactoryAdmin,
            remFactoryAdmin,
            addRwaFactoryAdmin,
            remRwaFactoryAdmin,
            addMinter,
            remMinter,
            addRWAMinter,
            remRWAMinter,
            withdrawTokenAddress,
            withdrawAmount,
            withdraw,
            genRandStr,
            serverConfig,
            userInfo};

    }
};

</script>
<style scoped>
h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: bold;
}
input.admin-avatar {
  display: none;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.admin-buttom-end {
  display: flex;
  flex-direction: row;
  align-items: end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.admin-bg-head {
  position: relative;
  width: 100%;
  background-size: cover;
}
.admin-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background: white;
  border: 1px #e766ff solid;
  display: flex;
}
.admin-bg-main {
  position: relative;
  width: 100%;
  background: #fff;
}
.admin-card-content {
  text-align: left;
  display: flex;
  flex-direction: row;
}
.admin-contents {
  width: 70%;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
img.admin-card-avatar {
  width: 128px;
  height: 128px;
}
.admin-save-button {
  display: flex;
  margin: 10px;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  width: fit-content;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.admin-withdraw-button {
  margin-top: 50px;
  margin-left: 20px;
  color: #404040;
  text-shadow: 0 0 5px #ff0078, 0 0 10px #ff0078, 0 0 15px #ff0078, 0 0 20px #ff0078, 0 0 30px #ff0078, 0 0 40px #ff0078;
  letter-spacing: 5px;
}
.admin-withdraw-button:hover {
  cursor: pointer;
  color: white;
}
.admin-modal {
  width: 700px;
}
.c-grid .o-flex-grid--item {
  background: #fff;
}
.o-flex-grid {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0 auto;
  overflow: auto;
}

.o-flex-grid--item-a {
  overflow: auto;
  width: 5%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-b {
  overflow: auto;
  width: 30%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-c {
  overflow: auto;
  width: 30%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-d {
  overflow: auto;
  width: 15%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-e {
  cursor: pointer;
  overflow: auto;
  width: 15%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-f {
  overflow: auto;
  color: red;
  cursor: pointer;
  width: 10%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.w-100 {
  width: 100%;
}

@media(max-width:800px)
.o-flex-grid {
  background: white;
  flex-direction: column;
  flex-wrap: nowrap;
}

.o-flex-grid--item {
  width: 100% !important;
}
.admin-box {
  margin-top: 50px;
  margin-bottom: 50px;
  background: white;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #9f71e7;
}
.news-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin: 10px;
}
.file-label {
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 12px 20px;
  margin: -2px 0px;
  font-size: .9em;
  box-sizing: border-box;
  border: 2px solid #dfdfdf;
  border-radius: 15px;
  background-image: url("../assets/default-image-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
}
input[type=file].news-image-input {
  display: none;
}
.address-box {
  width: 300px;
  margin-right: 10px;
}
.num-box {
  width: 200px;
  margin-right: 10px;
}
.flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: end;
}
.home-news-container-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  height: 250px;
  width: 100%;
  display: flex;
  background-image: url("../assets/news-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f8faff;
  margin-left: auto;
  margin-right: auto;
}
.news-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-right: 70px;
  max-height: 250px;
  overflow: hidden;
}
.home-news-image {
  margin: 10px 10px 10px 70px;
  background-size: contain;
  height: 128px;
}
</style>
<style scoped>
.tab-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: #666;
  transition: color 0.3s ease;
}

.tab.active {
  color: #333;
}

.indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 33%; /* Adjust based on number of tabs */
  background-color: #4a90e2;
  transition: transform 0.3s ease;
}

.tab-content {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  box-sizing: border-box;
}

/* Transition content */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.tab-content-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@keyframes gnarlySlideEnter {
  0% {
    transform: scale(0.8) rotate(0deg) translateX(100%);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) rotate(-0deg) translateX(-0%);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(0) translateX(0);
    opacity: 1;
  }
}

@keyframes gnarlySlideLeave {
  0% {
    transform: scale(1) rotate(0) translateX(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(2deg) translateX(10%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.8) rotate(-2deg) translateX(-100%);
    opacity: 0;
  }
}

.gnarly-slide-enter-active {
  animation: gnarlySlideEnter 0.6s cubic-bezier(0.7, 0, 0.2, 1);
}
.gnarly-slide-leave-active {
  animation: gnarlySlideLeave 0.6s cubic-bezier(-0.7, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(-100%);
}

.one-hundred-percent {
  width: 100%;
}

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.back-to-orders {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
}
.flex-col-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.subscript {
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 10px;
  color: rgb(34, 37, 92);
}
.subscript:hover {
  cursor: pointer;
  text-decoration: underline;
}
.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 2px solid #f8f8f8;
  padding-bottom: 10px;
}
.flex-column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.basic-button {
  border: 1px solid #efefef;
  border-radius: 7px;
  background: linear-gradient(307deg, #031020e3, #0c021ecf);
  color: #efefef;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 3px 10px;
  width: fit-content;
}
.basic-button:hover {
  cursor: pointer;
  text-decoration: underline;
}
.buttons-container {
  display: flex;
  margin-top: 30px;
  justify-content: flex-start;
  align-items: center;
}

</style>

